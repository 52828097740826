// @ts-nocheck
import React from "react"
import parse from "html-react-parser"
import {convertFromRaw} from "draft-js"
import {convertToHTML} from "draft-convert"
import {BLOCK_TYPE} from "draftail"
import {IMAGE_API_URL, MEDIA_API_URL} from "./consts"

const enum ENTITY_TYPE {
  LINK = "LINK",
  IMAGE = "IMAGE",
  HORIZONTAL_RULE = "HORIZONTAL_RULE",
  DOCUMENT = "DOCUMENT",
  EMBED = "EMBED"
}

const parseTextField: React.FC = (rawContent: any): any => {
  const exporterConfig = {
    blockToHTML: (block: any) => {
      if (block.type === BLOCK_TYPE.BLOCKQUOTE) {
        return <blockquote />
      }
      // Discard atomic blocks, as they get converted based on their entity.
      if (block.type === BLOCK_TYPE.ATOMIC) {
        return {
          start: "",
          end: ""
        }
      }
      return null
    },

    entityToHTML: (entity, originalText) => {
      if (entity.type === ENTITY_TYPE.LINK) {
        return <a href={entity.data.url}>{originalText}</a>
      }
      if (entity.type === ENTITY_TYPE.DOCUMENT) {
        return (
          <a href={MEDIA_API_URL + entity.data.url} target='_blank' rel='noopener noreferrer'>
            {originalText}
          </a>
        )
      }
      if (entity.type === ENTITY_TYPE.EMBED) {
        return entity.data.html
      }
      if (entity.type === ENTITY_TYPE.IMAGE) {
        return <img src={IMAGE_API_URL + entity.data.src} alt={entity.data.alt} />
      }
      if (entity.type === ENTITY_TYPE.HORIZONTAL_RULE) {
        return <hr />
      }
      return originalText
    }
  }

  const toHTML = (raw: any) => (raw ? convertToHTML(exporterConfig)(convertFromRaw(raw)) : "")
  return parse(toHTML(rawContent))
}

export default parseTextField
