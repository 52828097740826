import React, { useEffect, useState } from "react"
import customAxios from "../../common/axios"
import { IMAGE_API_URL } from "../../common/consts"
import { CapsArgs } from "../../common/interfaces/Caps"
import parseTextField from "../../common/parser"
import Wrapper from "../../components/Wrapper"
import "./Caps.css"

const Caps: React.FC = () => {
  const [capsData, setCapsData] = useState<CapsArgs | undefined>(undefined)
  // const [gameIndex, setGameIndex] = useState<number>(0)

  useEffect(() => {
    customAxios.get("/pages/?type=caps.CpasPage&fields=_,title,id,info,rules,image").then((res) => {
      const data: CapsArgs = res.data.items[0]
      setCapsData(data)
    })
  }, [])

  if (!capsData) {
    return <p>Loading</p>
  } else {
    return (
      <Wrapper left={true}>
        <div className="capsContainer">
          <h1>{capsData.title}</h1>
          <hr />
          <div>{parseTextField(capsData.info)}</div>
          <div>{parseTextField(capsData.rules)}</div>
          <img src={IMAGE_API_URL + capsData.image.meta.download_url} alt={capsData.image.meta.detail_url} />
        </div>
      </Wrapper>
    )
  }
}

export default Caps
