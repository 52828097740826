import { useEffect, useState } from 'react'
import { customMAtrosAxios } from '../../common/axios'
import Wrapper from '../../components/Wrapper/Wrapper'
import './Matros.css'

type MatrosProps = {
  id: string
  discord_id: string
  name: string
  points: number
  active: boolean
}

export const Matros = () => {
  // const [matros, setMatros] = useState<MatrosProps[]>([])

  // useEffect(() => {
  //   customMAtrosAxios.get('/list').then((res) => {
  //     const data: MatrosProps[] = res.data

  //     if (data.length > 0) {
  //       setMatros(data)
  //     }
  //   })
  // }, [])

  // if (matros.length === 0) {
  //   return (
  //     <Wrapper>
  //       <div className='wrapper'>
  //         <h1>Matroser</h1>
  //       </div>
  //     </Wrapper>
  //   )
  // }

  return (
    <Wrapper>
      <div className='wrapper'>
        {/* <div className='matrosWrapper'>
          <h2>Poäng</h2>
          {matros.map((matros, index) => {
            switch (index) {
              case 0:
                return (
                  <p key={index} className='list list-1'>
                    🥇{matros.name}: {matros.points} poäng
                  </p>
                )
              case 1:
                return (
                  <p key={index} className='list list-2'>
                    🥈{matros.name}: {matros.points} poäng
                  </p>
                )
              case 2:
                return (
                  <p key={index} className='list list-3'>
                    🥉{matros.name}: {matros.points} poäng
                  </p>
                )
              default:
                return (
                  <p key={index} className='list'>
                    {matros.name}: {matros.points} poäng
                  </p>
                )
            }
          })}
        </div> */}
        <div className='textWrapper'>
          <h1>Matroser</h1>
          <p>
            Under ens matrosperiod gör man både dumma, korkade och ibland med lite tur något bra beslut. Detta är något som vi i CapsPiraterna
            antecknar och följer genom att utdela poäng till de nuvarande matroserna. En matros poäng kan avgöra allt från att slippa en ökänd
            matrosdrink till att få straff inte ens en fulltidsalkolist skulle kunna tänka sig.
          </p>
        </div>
      </div>
    </Wrapper>
  )
}
