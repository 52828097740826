import "./OmOss.css"

import logo from "../../assets/images/logo_no_shadow.png"
import React, { useEffect, useState } from "react"
import customAxios from "../../common/axios"
import { AboutUsArgs } from "../../common/interfaces/AboutUs"
import parseTextField from "../../common/parser"
import Wrapper from "../../components/Wrapper"

const OmOss: React.FC = () => {
  const [aboutUs, setAboutUs] = useState<AboutUsArgs | undefined>(undefined)

  useEffect(() => {
    customAxios.get("pages/?type=about.AboutPage&fields=_,title,medlemar,who_are_we,id").then((res) => {
      const data: AboutUsArgs = res.data.items[0]
      setAboutUs(data)
    })
  }, [])

  if (!aboutUs) {
    return <p>Loading</p>
  } else {
    return (
      <Wrapper left={true}>
        <div className="aboutContainer">
          <h2>{"Vilka är vi?"}</h2>
          <span>{parseTextField(aboutUs.who_are_we)}</span>

          <div className="medlemar">
            <span>{parseTextField(aboutUs.medlemar)}</span>
          </div>

          <img src={logo} alt="Caps_logo" />
        </div>
      </Wrapper>
    )
  }
}

export default OmOss
