import { useEffect, useState } from "react"
import customAxios from "../../common/axios"
import { GameArgs } from "../../common/interfaces/Games"
import GameCard from "../../components/GameCard/GameCard"
import Wrapper from "../../components/Wrapper"
import "./Spel.css"

function Spel() {
  const [games, setGames] = useState<GameArgs[]>([])
  const [gameIndex, setGameIndex] = useState<number>(0)

  useEffect(() => {
    customAxios.get("/pages/?type=games.Game&fields=_,body,title,image,id").then((res) => {
      const data: GameArgs[] = res.data.items
      setGames(data.sort(() => Math.random() - 0.5))
    })
  }, [])

  if (games.length === 0) {
    return <p>Loading</p>
  }

  return (
    <Wrapper>
      <div className="spelTextContainer">
        <h2>Sugen på ett dryckesspel men vet inte vad ni ska köra?</h2>
        <p>No fear, piraterna hjälper er att bli fulla på nolltid!</p>
        <p>Klicka på kortet för att slumpa ett nytt spel</p>
      </div>
      <div onClick={() => setGameIndex((old) => (old + 1) % games.length)} className="gameCardWrapper">
        <GameCard key={games[gameIndex].id} game={games[gameIndex]} />
      </div>
    </Wrapper>
  )
}

export default Spel
