import "./HeaderMobile.css"

import { Link, NavLink } from "react-router-dom"
import logo from "../../assets/images/logo.png"
import Hamburger from "hamburger-react"
import CSS from "csstype"
import { useState } from "react"

function HeaderMobile() {
  const [isOpen, setOpen] = useState(false)

  const openStyle: CSS.Properties = {
    top: "60px",
  }

  return (
    <nav className="mobileNavWrapper">
      <div className="topBarMobile">
        <Link className="logolink" to={"/"}>
          <img src={logo} alt="caps_logo" className="scrollStyle" />
          <h3 style={{ color: "#555" }}>Caps Piraterna</h3>
        </Link>
        <Hamburger rounded={true} toggled={isOpen} color={"#777"} easing="ease-in-out" toggle={setOpen} />
      </div>
      <div className="listWrapperMobile" style={isOpen ? openStyle : undefined}>
        <NavLink onClick={() => setOpen(false)} className={"linkMobile"} to={"/"}>
          Hem
        </NavLink>
        <NavLink onClick={() => setOpen(false)} className={"linkMobile"} to={"/games"}>
          Spel
        </NavLink>
        <NavLink onClick={() => setOpen(false)} className={"linkMobile"} to={"/blog"}>
          Blogg
        </NavLink>
        <NavLink onClick={() => setOpen(false)} className={"linkMobile"} to={"/caps"}>
          Caps
        </NavLink>
        <NavLink onClick={() => setOpen(false)} className={"linkMobile"} to={"/about"}>
          Om Oss
        </NavLink>
      </div>
    </nav>
  )
}

export default HeaderMobile
