import { Link } from "react-router-dom"
import Wrapper from "../../components/Wrapper"
import "./Home.css"

function Home() {
  return (
    <>
      <Wrapper left={true}>
        <div className="homeContainer">
          <h2 className="homeTitle">Välkomna till Caps Piraterna hemsida!</h2>
          <p>
            Här finner du allt från olika dryckesspel, nyheter, blogginlägg och såklar information om Caps Piraterna och
            det legendariska spelet Caps!{" "}
          </p>
          <div>
            <h4>Sugen på att köra något dryckesspel?</h4>
            <div className="homeButton">
              <Link style={{ textDecoration: "none" }} to={"games"}>
                <p>Ta mig dit!</p>
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
      <div className="pirateShipContainer"></div>
    </>
  )
}

export default Home
