import "./Wrapper.css"

type Props = {
  left?: boolean
  children: JSX.Element | JSX.Element[]
}

const Wrapper: React.FC<Props> = ({ children, left }) => {
  return (
    <div className={"screen-container"} style={{ textAlign: left ? "left" : "center" }}>
      {children}
    </div>
  )
}

export default Wrapper
