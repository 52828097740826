import { Slide } from "react-slideshow-image"

import "react-slideshow-image/dist/styles.css"
import { IMAGE_API_URL } from "../../common/consts"
import styles from "./Slider.module.css"

interface SlideShowData {
  type: string
  value: {
    image_url: string
  }
  id: string
}

export interface SlideShowProps {
  slideshow: SlideShowData[]
}

const Slider: React.FC<SlideShowProps> = ({ slideshow }) => {
  return (
    <div className={styles.container}>
      <Slide easing="ease">
        {slideshow.map((slide, index) => {
          return (
            <div className={styles.slide} key={slide.id}>
              <div style={{ backgroundImage: `url(${IMAGE_API_URL + slide.value.image_url})` }}></div>
            </div>
          )
        })}
      </Slide>
    </div>
  )
}

export default Slider
