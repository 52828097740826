import React from "react"
import ReactDOM from "react-dom/client"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import App from "./App"
import "./index.css"
import Blogg from "./screens/Blogg/Blogg"
import BloggPost from "./screens/BloggPost/BloggPost"
import Caps from "./screens/Caps/Caps"
import Home from "./screens/Home/Home"
import OmOss from "./screens/OmOss/OmOss"
import Spel from "./screens/Spel/Spel"
import { Matros } from "./screens/Matroser/Matros"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<OmOss />} />
          <Route path='/games' element={<Spel />} />
          <Route path='/blog' element={<Blogg />} />
          <Route path='/blog/:slug' element={<BloggPost />} />
          <Route path='/caps' element={<Caps />} />
          <Route path='/matroser' element={<Matros />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)
