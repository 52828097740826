import axios from "axios"
import {API_URL, MATROS_URL} from "./consts"

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN"
axios.defaults.xsrfCookieName = "csrftoken"

const customAxios = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: {
    "Content-type": "application/json"
  }
})

export const customMAtrosAxios = axios.create({
  baseURL: MATROS_URL,
  withCredentials: true,
  headers: {
    "Content-type": "application/json"
  }
})

export default customAxios
