import './Header.css'

import { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/images/logo.png'

import CSS from 'csstype'

function Header() {
  const [offset, setOffset] = useState(0)

  const scrollLimit: number = 10
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    // clean up code
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  const navStyle: CSS.Properties = {
    color: offset > scrollLimit ? '#555' : 'white',
    borderBottomColor: offset > scrollLimit ? 'inherit rgba(0, 172, 193, 1)' : ''
  }

  return (
    <nav className='customNav' style={{ backgroundColor: offset > scrollLimit ? 'white' : '' }}>
      <div className='topBar'>
        <Link className='logolink' to={'/'}>
          <img src={logo} alt='caps_logo' className={offset > scrollLimit ? 'scrollStyle' : ''} />
          <h3 style={{ color: offset > scrollLimit ? '#555' : 'white' }}>Caps Piraterna</h3>
        </Link>
      </div>
      <div className='malvacomNav navbar'>
        <NavLink className={offset > scrollLimit ? 'link scrollActive' : 'link'} to={'/'} style={navStyle}>
          Hem
        </NavLink>
        <NavLink className={offset > scrollLimit ? 'link scrollActive' : 'link'} to={'/games'} style={navStyle}>
          Spel
        </NavLink>
        <NavLink className={offset > scrollLimit ? 'link scrollActive' : 'link'} to={'/blog'} style={navStyle}>
          Blogg
        </NavLink>
        <NavLink className={offset > scrollLimit ? 'link scrollActive' : 'link'} to={'/caps'} style={navStyle}>
          Caps
        </NavLink>
        <NavLink className={offset > scrollLimit ? 'link scrollActive' : 'link'} to={'/matroser'} style={navStyle}>
          Matroser
        </NavLink>
        <NavLink className={offset > scrollLimit ? 'link scrollActive' : 'link'} to={'/about'} style={navStyle}>
          Om Oss
        </NavLink>
      </div>
    </nav>
  )
}

export default Header
