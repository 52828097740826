import {useEffect, useState} from "react"
import {useCookies} from "react-cookie"
import {Outlet} from "react-router-dom"
import "./App.css"
import Header from "./components/Header/Header"
import HeaderMobile from "./components/Header/HeaderMobile"

import NotARobot from "./components/NotARobot/NotARobot"
import Ocean from "./components/Ocean/Ocean"

function App() {
  // eslint-disable-next-line
  const [cookies, setCookie, removeCookie] = useCookies(["caps-robot"])
  const [width, setWidth] = useState<number>(window.innerWidth)

  const isMobile = width <= 768

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange)
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange)
    }
  }, [])

  return (
    <div className='App'>
      <div className='header'>
        {isMobile ? <HeaderMobile /> : <Header />}
        <div className='inner-header flex'><Outlet /></div>
        <Ocean />
      </div>
    </div>
  )
}

export default App
