import React from "react"
import {Link} from "react-router-dom"
import {NewsArgs} from "../../common/interfaces/News"
import "./BlogItem.css"

interface BlogProps {
  post: NewsArgs
}

const BloggItem: React.FC<BlogProps> = ({post}) => {
  return (
    <div className='blogItemContainer'>
      <Link style={{textDecoration: "none"}} to={String(post.meta.slug)}>
        <h2>{post.title}</h2>
        <p>{post.date}</p>
      </Link>
    </div>
  )
}

export default BloggItem
