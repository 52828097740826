import { IMAGE_API_URL } from "../../common/consts"
import { GameArgs } from "../../common/interfaces/Games"
import parseTextField from "../../common/parser"
import "./GameCard.css"

interface GameArgsProps {
  game: GameArgs
}

const GameCard: React.FC<GameArgsProps> = ({ game }) => {
  return (
    <div className="gameCardContainer">
      <section>
        <div className="infoContainer">
          <h2>{game.title}</h2>
          <div className="ruleContainer">
            <h4>Regeler:</h4>
            <div>{parseTextField(game.body)}</div>
          </div>
        </div>
        <div className="imageWrapper">
          {game.image ? (
            <img src={IMAGE_API_URL + game.image.meta.download_url} alt={game.image.meta.detail_url} />
          ) : (
            <></>
          )}
        </div>
      </section>
    </div>
  )
}

export default GameCard
