import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import customAxios from "../../common/axios"
import { NewsPostArgs } from "../../common/interfaces/NewsPost"
import parseTextField from "../../common/parser"
import Slider from "../../components/Slider"
import Wrapper from "../../components/Wrapper"
import "./BloggPost.css"

type NewsPostParams = {
  slug: string
}

const BloggPost: React.FC = () => {
  const [newsPostData, setNewsPostData] = useState<NewsPostArgs>()

  const { slug } = useParams<NewsPostParams>()
  const navigate = useNavigate()

  useEffect(() => {
    customAxios.get(`/pages/?slug=${slug}&type=news.NewsPost&fields=_,body,title,id,date,slideshow`).then((res) => {
      const data: NewsPostArgs = res.data.items[0]
      setNewsPostData(data)
    })
  }, [slug])

  if (!newsPostData) {
    return <p>Loading</p>
  } else {
    return (
      <Wrapper left={true}>
        <div className="blogPostContainer">
          <div className="titleWrapper">
            <span onClick={() => navigate(-1)}>{"<"}</span>
            <h2 className="title">{newsPostData.title}</h2>
          </div>
          <p className="date">{newsPostData.date}</p>
          <div className="textParser">{parseTextField(newsPostData.body)}</div>
          <div className="bloggSlideshow">
            <Slider slideshow={newsPostData.slideshow} />
          </div>
        </div>
      </Wrapper>
    )
  }
}

export default BloggPost
