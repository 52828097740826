import { useEffect, useState } from "react"
import customAxios from "../../common/axios"
import { NewsArgs } from "../../common/interfaces/News"
import BloggItem from "../../components/BlogItem/BlogItem"
import Wrapper from "../../components/Wrapper"
import "./Blogg.css"

function Blogg() {
  const [blogPosts, setBlogPosts] = useState<NewsArgs[]>([])

  useEffect(() => {
    customAxios.get("/pages/?type=news.NewsPost&fields=_,title,id,slug,date").then((res) => {
      const data: NewsArgs[] = res.data.items
      setBlogPosts(data)
    })
  }, [])

  if (blogPosts.length === 0) {
    ;<p>Loading</p>
  }

  return (
    <Wrapper left={true}>
      <h2 className="blogTitle">Pirat Blogg</h2>
      <div className="blogItemWrapper">
        {blogPosts.map((post) => {
          return <BloggItem key={post.id} post={post} />
        })}
      </div>
    </Wrapper>
  )
}

export default Blogg
